import { Box, TextField, Typography, useTheme } from '@mui/material'
import loGet from 'lodash/get'
import React from 'react'
import { Control, Controller, FieldErrorsImpl } from 'react-hook-form'
import { DefaultValueMap } from '..'
import { GenericFormField } from '../GeneratedFormFields'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export type Options = {
  futureTime?: boolean
  minimumDate?: string
}

type ChainsDropdownProps = {
  isRequired?: boolean
  control: Control<DefaultValueMap, any>
  errors: FieldErrorsImpl<{
    [x: string]: any
  }>
  title?: string
  startTime: GenericFormField
  endTime?: GenericFormField
  opt?: Options
}

const futureTime = (value) => {
  return dayjs(value).utc().isAfter(dayjs().utc()) || 'Has to be in the future.'
}

const StartEndDate: React.FC<ChainsDropdownProps> = ({
  control,
  errors,
  title,
  startTime,
  endTime,
  opt,
}) => {
  const theme = useTheme()
  const startTimeRules = { validate: {} }
  if (startTime.isRequired) {
    startTimeRules['required'] = 'This field is required.'
  }

  const endTimeRules = { validate: {} }
  if (endTime?.isRequired) {
    endTimeRules['required'] = 'This field is required.'
  }

  if (opt?.futureTime) {
    startTimeRules.validate['futureTime'] = futureTime
    endTimeRules.validate['futureTime'] = futureTime
  }

  if (opt?.minimumDate) {
    startTimeRules.validate['largerThanMinimumTime'] = (value) => {
      const startTimeValue = new Date(
        loGet(control, `_fields.${startTime.name}._f.value`),
      ).getTime()
      const minimumTimeValue = new Date(opt.minimumDate).getTime()

      if (value) {
        return (
          minimumTimeValue < startTimeValue ||
          'End date has to be later than previous step date.'
        )
      }
    }
  }

  endTimeRules.validate['largerThanStartTime'] = (value) => {
    const startTimeValue = new Date(
      loGet(control, `_fields.${startTime.name}._f.value`),
    ).getTime()
    const endTimeValue = new Date(value).getTime()

    if (value) {
      return (
        endTimeValue > startTimeValue ||
        'End date has to be later than start date.'
      )
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 1 }}>
        <Typography
          variant="subtitle1"
          color={theme.palette.text.primary}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <TextField
          id="timezone"
          label="Timezone"
          variant="filled"
          placeholder="UTC"
          disabled
          value="UTC"
          sx={{ width: 120 }}
        />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            ml: 2,
            width: '100%',
          }}
        >
          <Controller
            control={control}
            rules={startTimeRules}
            name={startTime.name}
            render={({ field }) => (
              <TextField
                {...field}
                value={
                  field.value
                    ? dayjs.utc(field.value).format('YYYY-MM-DDTHH:mm')
                    : ''
                }
                onChange={(evt) =>
                  field.onChange(
                    dayjs(evt.target.value).utc(true).toISOString(),
                  )
                }
                error={startTime.error || !!loGet(errors, startTime.name)}
                helperText={loGet(errors, startTime.name)?.message as string}
                disabled={startTime.notUpdateable}
                label={startTime.title}
                variant="filled"
                type="datetime-local"
                sx={{
                  flex: 1,
                  '& input[type="datetime-local"]': {
                    colorScheme: 'dark',
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
          {endTime && (
            <>
              <Box sx={{ mt: 2 }}>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.text.secondary}
                  mx={2}
                >
                  ~
                </Typography>
              </Box>
              <Controller
                control={control}
                name={endTime.name}
                rules={endTimeRules}
                render={({ field }) => (
                  <TextField
                    {...field}
                    value={
                      field.value
                        ? dayjs.utc(field.value).format('YYYY-MM-DDTHH:mm')
                        : ''
                    }
                    onChange={(evt) =>
                      field.onChange(
                        dayjs(evt.target.value).utc(true).toISOString(),
                      )
                    }
                    error={endTime.error || !!loGet(errors, endTime.name)}
                    helperText={loGet(errors, endTime.name)?.message as string}
                    disabled={endTime.notUpdateable}
                    label={endTime.title}
                    variant="filled"
                    type="datetime-local"
                    sx={{
                      flex: 1,
                      '& input[type="datetime-local"]': {
                        colorScheme: 'dark',
                      },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default StartEndDate
